import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

export const createInterest = async (data) => {
    try {
        let result = await api.post(Apis.CreateInterest, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const fetchAllInterestData = async () => {
    try {
        let result = await api.get(Apis.GetInterestList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getInterestData = async (id) => {
    try {
        let result = await api.get(`${Apis.GetInterestById(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateInterestStatus = async (data, id) => {
    try {
        let result = await api.put(`${Apis.UpdateInterestStatus(id)}`, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateInterest = async (id, data) => {
    try {
        let result = await api.put(`${Apis.UpdateInterest(id)}`, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteInterest = async (id) => {
    try {
        let result = await api.delete(`${Apis.DeleteInterest(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};