import api from "../../../config/ApiConfig";
import { Apis } from "src/config";


export const createTrivia = async (data) => {
  try {
    let result = await api.post(Apis.CreateTrivia, data, { 'Access-Control-Allow-Origin': '*' })
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllTriviaList = async (data) => {
  try {
    let result = await api.get(Apis.GetTriviaList(data));
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteTrivia = async (id) => {

  try {
    let result = await api.delete(`${Apis.DeleteTrivia(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};


export const updateTrivia = async (id, data) => {

  try {
    let result = await api.put(`${Apis.UpdateTrivia(id)}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTriviaDataById = async (id) => {

  try {
    let result = await api.get(`${Apis.GetTriviaById(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateTriviaStatus = async (data, id) => {
  try {
    let result = await api.put(`${Apis.UpdateTriviaStatus(id)}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getRelatedTriviaByCategory = async (categoryId) => {
  try {
    let result = await api.get(`${Apis.GetRelatedTriviaByCategory(categoryId)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

