import axios from 'axios';
import jwt_decode from 'jwt-decode';

const API_URL = process.env.REACT_APP_BACKEND_API + '/api/users/';

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + 'login', {
        email,
        password
      })
      .then(response => {
        if (response.data.results) {
          localStorage.setItem('user', JSON.stringify(response.data?.results));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    window.location.reload();
  }

  register(name, email,role,password) {
    return axios.post(API_URL + 'signup', {
      name,
      email,
      role,
      password
    });
  }

  getCurrentUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      const decodedToken = jwt_decode(user.token);
      if (decodedToken.exp * 1000 < Date.now()) {
        // localStorage.removeItem('user');
        // window.location.reload();
      }
      return decodedToken;
    }
    return null;
  }

  getUserDetailsFromLocalStorage() {
      let userData = JSON.parse(localStorage.getItem('user'));
      if (userData === undefined || userData === null) {
          return null;
      }
      return userData?.userData;
  }

  getPermission(permissionData) {
    let permissionList = this.getUserDetailsFromLocalStorage()?.permission;
    let permissions = permissionList;
    if (permissions == null || permissionData === '') {
      return true;
    }
    return permissions.includes(permissionData) || permissions.includes("ADMIN");
  }
};

export default new AuthService();