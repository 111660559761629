import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

export const createCategory = async (data) => {
    try {
        let result = await api.post(Apis.CreateCategory, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateCategory = async (data) => {
    try {
        let result = await api.put(Apis.UpdateCategory, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const fetchAllCategory = async (page, size) => {
    try {
        let result = await api.get(Apis.GetCategorytList(page, size));
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getCategoryData = async (id) => {
    try {
        let result = await api.get(`${Apis.GetCategoryById(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateCategoryStatus = async (data) => {
    try {
        let result = await api.put(Apis.UpdateCategoryStatus, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteCategory = async (id) => {
    try {
        let result = await api.delete(`${Apis.DeleteCategory(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getEnableCategory = async () => {
    try {
        let result = await api.get(`${Apis.GetEnableCategory}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}