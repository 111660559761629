import { Navigate, useLocation } from "react-router-dom";
import authService from "../auth/auth.service";

export const ProtectedRoute = ({ children }) => {
  const  user = authService.getCurrentUser();
  let location = useLocation();
  //console.log("location", location)
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />
  }
  return children
}

export const PublicRoute = ({ children }) => {
  const user = authService.getCurrentUser();
  const location = useLocation();

  if (user && location.pathname === "/login") {
    // Authenticated user trying to access login, redirect to dashboard
    return <Navigate to="/dashboard" />;
  }

  if (user && location.pathname === "/register") {
    // Authenticated user trying to access login, redirect to dashboard
    return <Navigate to="/dashboard" />;
  }

  return children;
};