
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as FileSaver from "file-saver";

const ErorrMsgPopUp = withReactContent(alertPopUp);

export const deleteModalSwal = {
    title: '<h5>Are you sure you want to Delete?</h5>',
    focusConfirm: false,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    allowOutsideClick: false,
}

export const dublicateModalSwal = {
    title: '<h5>This email already in use?</h5>',
    focusConfirm: false,
    type: 'warning',
    confirmButtonText: 'OK',
    allowOutsideClick: false,
}

/* Common Alert pop up  */
export const showConfirmAlertPopUp = (title) => {
    return {
        title: title,
        focusConfirm: false,
        type: 'warning',
        showCancelButton: true,
        showCancelButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
    };
};

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const modules = {
    toolbar: [
        [{ header: "2" }, { header: "3" }, { header: "4" }, { font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ align: [] }],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link"],
    ],
    clipboard: {
        matchVisual: false,
    }
};

// export const epochTogmt = (date) => {
//     var newDate = new Date(date).toLocaleDateString("en-US")
//     return newDate;
// };

export const epochTogmt = (date) => {
    // let options = [{month: 'numeric'}, {day: 'numeric'}, {year: '2-digit'}];
    // function format(option) {
    //    let formatter = new Intl.DateTimeFormat('en', option);
    //    return formatter.format(new Date(date));
    // }
    // return options.map(format).join("/");
    let date1 = new Date(date);
    let day = date1.getDate();
    let month = date1.getMonth() + 1;
    let year = date1.getYear();
    return month > 12 ? 0 : month+"/"+day+"/"+year.toString().substr(-2);
}

export const formatDate = (timestamp) => {
    const dateObject = new Date(timestamp);
    const year = dateObject.getUTCFullYear();
    const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getUTCDate()).padStart(2, "0");
    return `${month}/${day}/${year}`;
}

export const errorMessagePopUp = (msg) => {
    ErorrMsgPopUp.fire({
        didOpen: () => {
            ErorrMsgPopUp.clickConfirm();
        }
    }).then((result) => {
        return ErorrMsgPopUp.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: <><h5>{msg}</h5></>
        })
    })
}

export const categoryOptions = [
    { value: 'ALL', label: 'ALL' },
    { value: 'START_UP', label: 'STARTUP' },
    { value: 'INDIVIDUAL', label: 'INDIVIDUAL' },
    { value: 'ENTERPRISE', label: 'ENTERPRISE' },
];

export const lookingForOptions = [
    { value: 'ALL', label: 'ALL' },
    { value: 'DIGITAL_MARKETING', label: 'DIGITAL MARKETING' },
    { value: 'CONTENT_MARKETING', label: 'CONTENT MARKETING ' },
    { value: 'MARKETING_STRATEGY', label: 'MARKETING STRATEGY' },
    { value: 'WEBSITE_DEVELOPMENT', label: 'WEBSITE DEVELOPMENT' },
    { value: 'PRODUCT_DEVELOPMENT', label: 'PRODUCT DEVELOPMENT' },
    { value: 'PRODUCT_CASE_STUDY_AND_VIDEOS', label: 'PRODUCT CASE STUDY AND VIDEOS' },
    { value: 'QA_AND_AUTOMATION', label: 'QA AND AUTOMATION' },
    { value: 'KICK_STARTER_STUDIO', label: 'KICK STARTER STUDIO' },
];

export const businessStatusOption = [
    { value: 'ALL', label: 'ALL' },
    { value: 'NEW', label: 'NEW' },
    { value: 'INVALID', label: 'INVALID' },
    { value: 'REJECTED', label: 'REJECTED' },
    { value: 'QUALIFIED', label: 'QUALIFIED' },
    { value: 'CLOSED', label: 'CLOSED' },
    { value: 'REPEATED', label: 'REPEATED' }
]

export const applicantStausOptions = [
    { value: 'NEW', label: 'NEW' },
    { value: 'OFFERED', label: 'OFFERED' },
    { value: 'KEPT_ON_HOLD', label: 'KEPT ON HOLD' },
    { value: 'JOINED', label: 'JOINED' },
    { value: 'INVOLVED', label: 'INVOLVED' },
    { value: 'REJECTED', label: 'REJECTED' },
    { value: 'OFFER_DENIED', label: 'OFFER DENIED' }
];

export const stausOptions = [
    { value: "ALL", label: "All" },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'ENABLE', label: 'Enable' },
    { value: 'DISABLE', label: 'Disable' }
];

export const productTypeOptions = ["NEWS_API", "SHORT_LINK", "FEEDBACK_AND_MARKETING" , "MESSAGE_CHAT" ]
export const paymentTypeOptions = ["FULL", "PARTIALLY"]

export async function download_file(e, imageSrc) {
    e.preventDefault();
    const response = await fetch(imageSrc,{ mode: 'no-cors', responseType: "blob"});
    const blobImage = await response.blob();
    const href = URL.createObjectURL(blobImage);
    var parsedUrl = imageSrc.substring(imageSrc.lastIndexOf("/") + 1);
    let filename = parsedUrl.substring(parsedUrl.indexOf('_')+1)
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    FileSaver.saveAs(imageSrc, filename);
    // anchorElement.download = filename;
    // document.body.appendChild(anchorElement);
    // anchorElement.click();
    // document.body.removeChild(anchorElement);
    // window.URL.revokeObjectURL(href);
}

export const tabInfo = [
    { value: 'PERSONAL_INFORMATION', label: 'Personal Information' },
    { value: 'SUBSCRIPTION_HISTORY', label: 'Subscription History' },
    { value: 'TAX_INFORMATION', label: 'Tax Information' },
    { value: 'BILLING_DETAILS', label: 'Billing Details' },
  ];

export const planOptions = [
    { value: 'STANDARD', label: 'STANDARD' },
    { value: 'PROFESSIONAL', label: 'PROFESSIONAL' },
    { value: 'CORPORATE', label: 'CORPORATE' },

]