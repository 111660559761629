import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

let uploadFileHeader = {
    headers: { "Content-Type": "multipart/form-data" }
};


export const uploadAttachmentFile = async (file) => {
    try {
        const attachment = await api.post(Apis.UploadAttachmentData, file, uploadFileHeader);
        if (attachment.data.error) {
            console.log("Error", attachment.data.error);
            return null;
        }
        return attachment.data;
    } catch (error) {
        console.log(error);
    }
}