let token = JSON.parse(localStorage.getItem('user'))?.token

export const storageSetting = (pageId) => {
    return {
        type: "remote",
        stepsBeforeSave: 1,
        contentTypeJson: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${process.env.REACT_APP_API}`,
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
            "Access-Control-Allow-Headers": "X-Requested-With",
            "Sec-Fetch-Site": "cross-site",
            "token": token
        },
        "cors": {
            "enabled": true,
            "origin": `${process.env.REACT_APP_API}`,
        },
        params: {
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_API}`,
        },
        id: "mycustom-",
        urlStore: `${process.env.REACT_APP_BACKEND_API}/api/blog/pages/${pageId}/content`,
        urlLoad: `${process.env.REACT_APP_BACKEND_API}/api/blog/pages/${pageId}/content`,
    };
};