import api from "../../../config/ApiConfig";
import { Apis } from "src/config";


export const getAllUserViewQuery = async (data) => {
    try {
        let result = await api.get(Apis.GetUserViewQueryList(data))
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getUserViewQueryById = async (id) => {
    try {
        let result = await api.get(Apis.GetUserViewQueryDetails(id))
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const changeUserViewQueryStatus = async (data) => {
    try {
        let result = await api.put(Apis.ChangeUserViewQueryStatus, data)
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteUserViewQuery = async (id) => {
    try {
        let result = await api.delete(Apis.DeleteUserViewQuery(id))
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};