import React from "react";
import PropTypes from "prop-types";
import { CFormTextarea, CFormLabel } from "@coreui/react";

const TextArea = ({
    name,
    type,
    placeholder,
    onChange,
    className,
    value,
    error,
    children,
    label,
    group,
    text,
    required,
    disabled,
    ...props
}) => {
    return (
        <div className={`form-group`}>
            <CFormLabel htmlFor="formFile">{label} {required && <span className="text-danger">*</span>}</CFormLabel>
            <CFormTextarea
                rows="4"
                cols="50"
                //label={label}
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                text={text}
                className={"form-control" + (error ? " error" : "")}
                disabled={disabled || false}
            ></CFormTextarea>
            <span style={{ color: "red" }}>{error}</span>
        </div>
    );
};

TextArea.defaultProps = {
    type: "text",
    className: "",
};

TextArea.prototype = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default TextArea;