const Apis = {

    GetUserList: `${process.env.REACT_APP_BACKEND_API}/api/users/users`,
    DeleteUser: (id) => `${process.env.REACT_APP_BACKEND_API}/api/users/users/${id}`,
    UpdateUser: `${process.env.REACT_APP_BACKEND_API}/api/users/update/data`,
    UpdateUserPassword:`${process.env.REACT_APP_BACKEND_API}/api/users/update/password`,
    GetUserDetails: (id) => `${process.env.REACT_APP_BACKEND_API}/api/users/users/${id}`,
    GetEnableRoleList: `${process.env.REACT_APP_BACKEND_API}/api/role/get-enable-role`,

    // Business  API
    // GetBusinessList: `${process.env.REACT_APP_BACKEND_API}/api/business/business-requests`,
    GetBusinessList: (data) =>  `${process.env.REACT_APP_BACKEND_API}/api/business/business-requests?category=${data?.category}&lookingFor=${data?.lookingFor}&status=${data?.status}&page=${data?.page}&limit=${data?.size}`,
    GetBusinessDetails: `${process.env.REACT_APP_BACKEND_API}/api/business/business-requests/`,
    DeleteBusinessQuery: (id) => `${process.env.REACT_APP_BACKEND_API}/api/business/business-requests?id=${id}`,
    ChangeStatusBusinessQuery: `${process.env.REACT_APP_BACKEND_API}/api/business/change-status`,

    //Business Remarks API
    GetBusinessRemarksList: (data) => `${process.env.REACT_APP_BACKEND_API}/api/business/remarks/list?businessQueryId=${data?.businessQueryId}&type=${data?.type}&page=${data?.page}&limit=${data?.size}`,
    CreateBusinessRemarks: `${process.env.REACT_APP_BACKEND_API}/api/business/remarks/create`,
    UpdateBusinessRemarks: `${process.env.REACT_APP_BACKEND_API}/api/business/remarks/update`,
    GetBusinessRemarksDataById: (data) => `${process.env.REACT_APP_BACKEND_API}/api/business/remarks/getById?id=${data?.id}&type=${data?.type}`,
    DeleteBusinessRemarks: (id) => `${process.env.REACT_APP_BACKEND_API}/api/business/remarks/delete?id=${id}`,
    ChangeBusinessRemarksStatus: `${process.env.REACT_APP_BACKEND_API}/api/business/remarks/change-status`,

    // Testimonial API
    GetTestimonialList: `${process.env.REACT_APP_BACKEND_API}/api/testimonial/testimonials`,
    AddTestimonial: `${process.env.REACT_APP_BACKEND_API}/api/testimonial/testimonials`,

    //Blog API
    CreateBlog: `${process.env.REACT_APP_BACKEND_API}/api/blog/create`,
    EditBlog: (id) => `${process.env.REACT_APP_BACKEND_API}/api/blog/update/${id}`,
    GetBlogData: (pageId) => `${process.env.REACT_APP_BACKEND_API}/api/blog/get/${pageId}`,
    GetBlogList: (data) => `${process.env.REACT_APP_BACKEND_API}/api/blog/pages?category=${data?.category}&status=${data?.status}&searchData=${data?.query}&page=${data?.page}&size=${data?.size}`,
    UpdateBlogStatus: `${process.env.REACT_APP_BACKEND_API}/api/blog/update/status`,
    DeleteBlog: (id) => `${process.env.REACT_APP_BACKEND_API}/api/blog/pages/${id}`,
    GetRelatedBlogByCategory: (categoryId) => `${process.env.REACT_APP_BACKEND_API}/api/blog/relatedByCategory?categoryId=${categoryId}`,

    //Upload attachment
    UploadAttachmentData: `${process.env.REACT_APP_BACKEND_API}/api/file/upload`,

    //Team API
    CreateTeam: `${process.env.REACT_APP_BACKEND_API}/api/wse/team`,
    GetTeamList: `${process.env.REACT_APP_BACKEND_API}/api/wse/team`,
    DeleteTeam: (id) => `${process.env.REACT_APP_BACKEND_API}/api/wse/team/${id}`,
    UpdateTeam: (id) => `${process.env.REACT_APP_BACKEND_API}/api/wse/team/${id}`,
    TeamById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/wse/team/${id}`,
    UpdateTeamStatus: `${process.env.REACT_APP_BACKEND_API}/api/wse/update/status`,

    //Persona API
    CreatePerosna: `${process.env.REACT_APP_BACKEND_API}/api/persona/personas`,
    GetPerosnaList: `${process.env.REACT_APP_BACKEND_API}/api/persona/personas`,
    UpdatePersona: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/personas/${id}`,
    DeletePersona: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/personas/${id}`,
    GetPerosnaById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/personas/${id}`,
    UpdatePersonaStatus: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/personas/${id}/status`,

    //Interest API
    CreateInterest: `${process.env.REACT_APP_BACKEND_API}/api/persona/interests`,
    GetInterestList: `${process.env.REACT_APP_BACKEND_API}/api/persona/interests`,
    UpdateInterest: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/interests/${id}`,
    GetInterestById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/interests/${id}`,
    UpdateInterestStatus: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/interests/${id}/status`,
    DeleteInterest: (id) => `${process.env.REACT_APP_BACKEND_API}/api/persona/interests/delete/${id}`,


    //Category API
    CreateCategory: `${process.env.REACT_APP_BACKEND_API}/api/categories/create`,
    GetCategorytList: (page, size) => `${process.env.REACT_APP_BACKEND_API}/api/categories/get?page=${page}&limit=${size}`,
    UpdateCategory: `${process.env.REACT_APP_BACKEND_API}/api/categories/update`,
    GetCategoryById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/categories/getById?id=${id}`,
    UpdateCategoryStatus: `${process.env.REACT_APP_BACKEND_API}/api/categories/change/status`,
    DeleteCategory: (id) => `${process.env.REACT_APP_BACKEND_API}/api/categories/delete?id=${id}`,
    GetEnableCategory: `${process.env.REACT_APP_BACKEND_API}/api/categories/get-enable-categories`,

    //Position API
    CreatePostion: `${process.env.REACT_APP_BACKEND_API}/api/wse/positions`,
    GetPostiontList: `${process.env.REACT_APP_BACKEND_API}/api/wse/positions`,
    UpdatePostion: (id) => `${process.env.REACT_APP_BACKEND_API}/api/wse/positions/${id}`,
    GetPostionById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/wse/positions/${id}`,
    UpdatePostionStatus: `${process.env.REACT_APP_BACKEND_API}/api/wse/positions/update/status`,
    DeletePostion: (id) => `${process.env.REACT_APP_BACKEND_API}/api/wse/positions/${id}`,
    GetEnablePostion: `${process.env.REACT_APP_BACKEND_API}/api/wse/enabledPositions`,

    //Trivia API
    CreateTrivia: `${process.env.REACT_APP_BACKEND_API}/api/trivia/create`,
    GetTriviaList: (data) => `${process.env.REACT_APP_BACKEND_API}/api/trivia/list?category=${data?.category}&status=${data?.status}&searchData=${data?.query}&page=${data?.page}&size=${data?.size}`,
    UpdateTrivia: (id) => `${process.env.REACT_APP_BACKEND_API}/api/trivia/update/${id}`,
    GetTriviaById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/trivia/get/${id}`,
    UpdateTriviaStatus: (id) => `${process.env.REACT_APP_BACKEND_API}/api/trivia/status/${id}`,
    DeleteTrivia: (id) => `${process.env.REACT_APP_BACKEND_API}/api/trivia/delete/${id}`,
    GetRelatedTriviaByCategory: (categoryId) => `${process.env.REACT_APP_BACKEND_API}/api/trivia/relatedByCategory?categoryId=${categoryId}`,

    //Infrograpic API
    CreateInfrograpic: `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/create`,
    GetInfrograpicList: (data) => `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/list?category=${data?.category}&status=${data?.status}&searchData=${data?.query}&page=${data?.page}&size=${data?.size}`,
    UpdateInfrograpic: (id) => `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/update/${id}`,
    GetInfrograpicById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/get/${id}`,
    UpdateInfrograpicStatus: (id) => `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/status/${id}`,
    DeleteInfrograpic: (id) => `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/delete/${id}`,
    GetRelatedInfograpicByCategory: (categoryId) => `${process.env.REACT_APP_BACKEND_API}/api/infrograpic/relatedByCategory?categoryId=${categoryId}`,

    // Editor Asset Image fetch
    GetEditorImageAttachments: (id) => `${process.env.REACT_APP_BACKEND_API}/api/blog/editor/attachment?pageId=${id}`,

    // Career Page
    GetApplicantList: (data) => `${process.env.REACT_APP_BACKEND_API}/api/career/applicant/list?status=${data?.status}&metaJobDescriptionId=${data?.metaJobDescriptionId}&page=${data?.page}&size=${data?.perPage}`,
    ChangeApplicantStatus: `${process.env.REACT_APP_BACKEND_API}/api/career/applicant/change-status`,
    GetApplicantDataById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/applicant/getById?id=${id}`,
    UpdateApplicantData: `${process.env.REACT_APP_BACKEND_API}/api/career/applicant/updateById`,

    /* Role Apis */
    CreateRole: `${process.env.REACT_APP_BACKEND_API}/api/role/create`,
    GetUserRoleList: `${process.env.REACT_APP_BACKEND_API}/api/role/list`,
    GetUserRoleById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/role/getById?id=${id}`,
    UpdateUserRole: `${process.env.REACT_APP_BACKEND_API}/api/role/update`,
    DeleteUserRole: (id) => `${process.env.REACT_APP_BACKEND_API}/api/role/delete?id=${id}`,
    UpdateRoleStatus: `${process.env.REACT_APP_BACKEND_API}/api/role/change-status`,

    //Career Department API
    GetDepartmentList: `${process.env.REACT_APP_BACKEND_API}/api/career/departments/list`,
    CreateDepartment: `${process.env.REACT_APP_BACKEND_API}/api/career/departments/create`,
    GetDepartmentById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/departments/getById?id=${id}`,
    UpdateDepartmentById: `${process.env.REACT_APP_BACKEND_API}/api/career/departments/update`,
    UpdateDepartmentStatus: `${process.env.REACT_APP_BACKEND_API}/api/career/departments/status`,
    DeleteDepartment: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/departments/delete?id=${id}`,

    //Career Job Position API
    CreateJobPosition: `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/create`,
    GetJobPositionById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/getById?id=${id}`,
    UpdateJobPosition: `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/update`,
    UpdateJobPositionStatus: `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/status`,
    DeleteJobPosition: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/delete?id=${id}`,
    GetJobPositionList: `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/list`,
    GetEnableJobPositionList: `${process.env.REACT_APP_BACKEND_API}/api/career/job-positions/enable-list`,

    //Career Job Description API
    CreateJobDescription: `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/create`,
    GetJobDescriptionById: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/getById?id=${id}`,
    UpdateJobDescription: `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/update`,
    UpdateJobDescriptionStatus: `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/status`,
    DeleteJobDescription: (id) => `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/delete?id=${id}`,
    GetJobDescriptionList: (jobType) => `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/list?jobType=${jobType}`,
    GetEnableJobDescriptionList: `${process.env.REACT_APP_BACKEND_API}/api/career/job-description/enable-list`,

    //Career Meta Job Description API
    CreateMetaJobDescription: `${process.env.REACT_APP_BACKEND_API}/api/career/meta-job-description/create`,
    UpdateMetaJobDescriptionStatus: `${process.env.REACT_APP_BACKEND_API}/api/career/meta-job-description/status`,
    GetMetaJobDescription: (data) => `${process.env.REACT_APP_BACKEND_API}/api/career/meta-job-description/list?jobDescriptionId=${data?.jobDescriptionId}&status=${data?.status}&page=${data?.page}&size=${data?.size}`,

    //User Code API
    CreateSignUpCode: `${process.env.REACT_APP_productBackendServiceURL}/signup_code/create`,
    SignUpCodeList: `${process.env.REACT_APP_productBackendServiceURL}/signup_code/list`,
    UpdateSignUpCodeStaus: `${process.env.REACT_APP_productBackendServiceURL}/signup_code/update`,
    DeleteSignUpCode: (id) => `${process.env.REACT_APP_productBackendServiceURL}/signup_code/delete?id=${id}`,

    //Subscription API
    CreatePaymentLink: `${process.env.REACT_APP_productBackendServiceURL}/payment/save`,
    PaymentLinkList:(data) => `${process.env.REACT_APP_productBackendServiceURL}/summary/back-office/subscription-summary?page=${data.page}&size=${data.size}`,
    PaymentReceivedList: `${process.env.REACT_APP_productBackendServiceURL}/payment/list`,
    PaymentRefundedList: `${process.env.REACT_APP_productBackendServiceURL}/payment/list`,
    PaymentTransactionList: `${process.env.REACT_APP_productBackendServiceURL}/payment/list`,

    //User View Query API
    GetUserViewQueryList: (data) => `${process.env.REACT_APP_BACKEND_API}/api/user-view-query/list?status=${data?.status}&page=${data?.page}&size=${data?.size}`,
    GetUserViewQueryDetails: (id) => `${process.env.REACT_APP_BACKEND_API}/api/user-view-query/get?id=${id}`,
    ChangeUserViewQueryStatus: `${process.env.REACT_APP_BACKEND_API}/api/user-view-query/status`,
    DeleteUserViewQuery: (id) => `${process.env.REACT_APP_BACKEND_API}/api/user-view-query/delete?id=${id}`,

    /*Product Apis */
    CreateProducts: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/product/create`,
    GetProductList: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/product/list`,
    UpdateProduct:  `${process.env.REACT_APP_productBackendServiceURL}/backoffice/product/update`,
    DeleteProduct:  (id) => `${process.env.REACT_APP_productBackendServiceURL}/backoffice/product/delete?id=${id}`,
    GetProductById: (id) => `${process.env.REACT_APP_productBackendServiceURL}/backoffice/product/get?id=${id}`,

    /*Subscription plan Apis */
    CreateSubscriptionPlan: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/create`,
    GetSubcriptionPlanList: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/list`,
    UpdateSubscriptionPlan: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/update`,
    UpdateSubscriptionPlanStatus: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/update-status`,
    GetPlanById: (id) => `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/get?id=${id}`,
    DeletePlan: (id) => `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/delete?id=${id}`,
    GetEnableSubcriptionPlanList: `${process.env.REACT_APP_productBackendServiceURL}/backoffice/subscription/list-enabled`,

    /* UserDetails Apis */
    UserFormData: (id) => `${process.env.REACT_APP_productBackendServiceURL}/user/get?id=${id}`,
    UserTransactinHistory: (data) => `${process.env.REACT_APP_productBackendServiceURL}/summary/subscription-history?userId=${data.userId}&page=${data.page}&size=${data.size}`,
    UseraxInfromation: (id) => `${process.env.REACT_APP_productBackendServiceURL}/tax-information/get?userId=${id}`,
    BillingDeatilsData: (data) => `${process.env.REACT_APP_productBackendServiceURL}/invoice/list?userId=${data.userId}&page=${data.page}&size=${data.size}`,
    SignupUserList: (data) => `${process.env.REACT_APP_productBackendServiceURL}/user/list?page=${data.page}&size=${data.size}`,






}

export { Apis };