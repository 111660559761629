import { Apis } from "src/config";
import api from "../../../config/ApiConfig";

/* create plan */
export const createSubscriptionPlan = async (data) =>{
    try{
        let result = await api.post(Apis.CreateSubscriptionPlan, data);
        if(result.data){
            return result.data;
        }
    }catch(error){
        console.log("Error", error);
        return error.response?.data
    }
};

/* update Plan */
export const updateSubscriptionPlan = async (data) => {
    try {
      let result = await api.put(Apis.UpdateSubscriptionPlan,data);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return error.response?.data
    }
};

/* get all Subscription pLan */
export const getSubscriptionPlaneList = async () => {
    try {
      let result = await api.get(Apis.GetSubcriptionPlanList);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

/* get enable Subscription pLan */
export const getEnablePlanList = async () => {
    try {
      let result = await api.get(Apis.GetEnableSubcriptionPlanList);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};




/* get Plan by id */
export const getPlanById = async (id) => {
        try {
      let result = await api.get(`${Apis.GetPlanById(id)}`);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

/* delete plan */
export const deleteSubscriptionPlan = async (id) => {
  try {
    let result = await api.delete(Apis.DeletePlan(id));
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error.response?.data
  }
};

/* change plan status*/
export const updateSubscriptionPlanStatus = async (data) => {
    try {
      let result = await api.put(`${Apis.UpdateSubscriptionPlanStatus}`, data);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };








