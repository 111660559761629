import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

/* create role */
export const createRole = async (data) =>{
    try{
        let result = await api.post(Apis.CreateRole, data);
        if(result.data){
            return result.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
};

/* get all user role */
export const getAllUserRole = async () => {
    try {
      let result = await api.get(Apis.GetUserRoleList);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

/* get user role by id */
export const getUserRoleById = async (id) => {
    try {
      let result = await api.get(`${Apis.GetUserRoleById(id)}`);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

/* update user role */
export const updateUserRole = async (data) => {
    try {
      let result = await api.put(Apis.UpdateUserRole,data);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

/* delete user role */
export const deleteUserRole = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteUserRole(id));
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

/* update user role  status*/
export const updateRoleStatus = async (data) => {
  try {
    let result = await api.put(Apis.UpdateRoleStatus,data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};




