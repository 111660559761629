
import { useEffect, useState } from "react";

const StatusDetails = (props) => {

    const [status, setStatus] = useState(props.status);

    return (
        <div>
            {status === "DRAFT" &&
                <span>Draft</span>
            }
            {(status === "ENABLE" || status === "ENABLED") &&
                <span>Enabled</span>
            }
            {(status === "DISABLE" || status === "DISABLED") &&
                <span>Disabled</span>
            }
            {status === "NEW" &&
                <span>New</span>
            }
            {status === "INVALID" &&
                <span>Invalid</span>
            }
            {status === "REJECTED" &&
                <span>Rejected</span>
            }
            {status === "QUALIFIED" &&
                <span>Qualified</span>
            }
            {status === "CLOSED" &&
                <span>Closed</span>
            }
            {status === "REPEATED" &&
                <span>Repeated</span>
            }
            {status === "INVOLVED" &&
                <span>Involved</span>
            }
            {status === "OFFER_DENIED" &&
                <span>Offer denied</span>
            }
            {status === "KEPT_ON_HOLD" &&
                <span>Kept on hold</span>
            }
            {status === "OFFERED" &&
                <span>Offered</span>
            }
            {status === "JOINED" &&
                <span>Joined</span>
            }
            {status === "Offered" &&
                <span>Offered</span>
            }
            {status === "OPEN" &&
                <span>Open</span>
            }
            {status === "PUBLISH" &&
                <span>Published</span>
            }
            {status === "CONSUMED" &&
                <span>Consumed</span>
            }
        </div>
    );
};

export default StatusDetails;