import api from "../../../config/ApiConfig";
import { Apis } from "src/config";


export const createInfograpic = async (data) => {
  try {
    let result = await api.post(Apis.CreateInfrograpic, data, { 'Access-Control-Allow-Origin': '*' })
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllInfrograpicList = async (data) => {
  try {
    let result = await api.get(Apis.GetInfrograpicList(data));
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteInfrograpic = async (id) => {

  try {
    let result = await api.delete(`${Apis.DeleteInfrograpic(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};


export const updateInfrograpic = async (id, data) => {

  try {
    let result = await api.put(`${Apis.UpdateInfrograpic(id)}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getInfrograpicDataById = async (id) => {

  try {
    let result = await api.get(`${Apis.GetInfrograpicById(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateInfrograpicStatus = async (data, id) => {
  try {
    let result = await api.put(`${Apis.UpdateInfrograpicStatus(id)}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getRelatedInfograpicByCategory = async (id) => {
  try {
    let result = await api.get(`${Apis.GetRelatedInfograpicByCategory(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};