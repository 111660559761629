import { Link } from "react-router-dom";
import StatusDetails from "../../utils/StatusDetails";
import StatusDrop from "../../utils/StatusDrop";
import { v4 as uuidv4 } from 'uuid';
import authService from "src/auth/auth.service";
import { cilInfo } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

export const tableBusinessConstant = (getBusinessList, handleConfirmModal) => {
    return [
        {
            id: 1,
            name: "Date",
            cell: (row) => {
                return (
                    <>
                        <Link to={`/business/details/${row?.id}`}>
                            <span className="sub">{row.createTime}</span>
                        </Link>
                        {authService.getPermission("REMARKS_TAB") &&
                            <Link to={{pathname: "/business/remarks"}} state={{ "businessQueryId": row?.id, "businessQueryStatus": row.status}} style={{marginLeft: "8px"}}>
                                <CIcon icon={cilInfo} customClassName="nav-icon" width={20} />
                            </Link>
                        }
                    </>
                    
                );
            },
        },
        {
            id: 2,
            name: "First Name",
            selector: (row) => row.firstName
        },
        {
            id: 3,
            name: "Last Name",
            selector: (row) => row.lastName
        },
        {
            id: 4,
            name: "Company Name",
            selector: (row) => row.companyName
        },
        {
            id: 5,
            name: "Action",
            cell: (row) => {
                return (
                    <StatusDrop
                        id={row.id}
                        status={row.status}
                        type={'BUSINESS_QUERY'}
                        action={getBusinessList}
                        key={uuidv4()}
                        publishPermission={"BUSINESS_STATUS_UPDATE"}
                    />
                );
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 6,
            name: "Status",
            cell: (row) => {
                return (
                    <StatusDetails status={row.status} key={uuidv4()} />
                );
            },
            sortable: true,
            reorder: true,
        },
        {
            id: 7,
            name: "Country code",
            selector: (row) => "+" + row.countryISDCode
        },
        {
            id: 8,
            name: "Phone No.",
            selector: (row) => row.contactNumber
        },
        {
            id: 9,
            name: "Email",
            selector: (row) => row.email
        },
        {
            id: 10,
            name: "Website",
            selector: (row) => row.website
        },
        {
            id: 11,
            name: "Tenure",
            selector: (row) => row?.estimatedBudget?.type
        },
        {
            id: 12,
            name: "Currency Type",
            selector: (row) => row?.location?.country === "India" ? "INR" : "USD"
        },
        {
            id: 13,
            name: "Estimated Budget",
            selector: (row) => row.estimatedBudget?.amount
        },
        {
            id: 14,
            name: "Country",
            selector: (row) => row.location?.country
        },
        {
            id: 15,
            name: "State",
            selector: (row) => row.location?.state
        },
        {
            id: 16,
            name: "City",
            selector: (row) => row.location?.city
        },
        {
            id: 17,
            name: "Category",
            cell: (row) => {
                return (
                    <>
                        <span className="sub">{row.category}</span>
                    </>
                    
                );
            },
            sortable: true,
            reorder: true,
            style: {
                color: "#202124",
                fontSize: "14px",
                fontWeight: 500,
            },
        },
        {
            id: 18,
            name: "Looking For",
            selector: (row) => row.lookingFor
        },
        {
            id: 19,
            name: "Closed By",
            selector: (row) => row.closedBy ? row.closedBy : "-"
        },
        {
            id: 20,
            name: "Delete",
            cell: (row) => {
                return <i className={"fa-regular fa-trash-can" + (row.status === "ENABLE" || !authService.getPermission("DELETE") ? " disable" : "")} onClick={(e) => { handleConfirmModal(row.id) }}></i>;
            },
            sortable: true,
            reorder: true,
        },
    ];
};
