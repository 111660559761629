import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

export const fetchAllDepartmentData = async () => {
    try {
        let result = await api.get(Apis.GetDepartmentList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const createDepartment = async (data) =>{
    try{
        let result = await api.post(Apis.CreateDepartment, data);
        if(result.data){
            return result.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
};

export const updateDepartment = async (data) => {
    try{
        let result = await api.put(Apis.UpdateDepartmentById, data);
        if(result.data){
            return result.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const deleteDepartment = async (id) =>{
    try{
        let result = await api.delete(Apis.DeleteDepartment(id));
        if(result.data){
            return result.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}


export const fetchDepartmentById = async (id) =>{
    try{
        let department = await api.get(Apis.GetDepartmentById(id));
        if(department.data){
            return department.data;
        }
    }catch(error){
        console.log("error", error);
        return null;
    }
}

export const departmentStatusChange = async (data) => {
    try {
        let status = await api.put(Apis.UpdateDepartmentStatus, data);
        if(status.data){
            return status.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const createPosition = async (data) => {
    try{
        let position = await api.post(Apis.CreateCareerPosition, data);
        if(position.data){
            return position.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}

export const fetchPositionById = async (id) =>{
    try{
        let position = await api.get(Apis.GetCareerPositionById(id));
        if(position.data){
            return position.data
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const updatePositionById = async (id, data) => {
    try{
        let position = await api.put(Apis.UpdateCareerPositionById(id), data);
        if(position.data){
            return position.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const deletePosition = async (id) =>{
    try{    
        let position = await api.delete(Apis.UpdateCareerPositionById(id));
        if(position.data){
            return position.data
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}


export const fetchAllPositionData = async (jobType) => {
    try {
        let result = await api.get(Apis.GetPositionList(jobType));
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const positionStatusChange = async (id, data) =>{
    try{
        let position = await api.put(Apis.UpdateCareerPositionStatus(id), data);
        if(position.data){
            return position.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}

export const fetchAllApplicantData = async (data) => {
    try {
        let result = await api.get(Apis.GetApplicantList(data));
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const changeApplicantStatusData = async (data) => {
    try {
        let result = await api.put(Apis.ChangeApplicantStatus, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getApplicantDataById = async (id) => {
    try {
        let result = await api.get(Apis.GetApplicantDataById(id));
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateApplicantData = async (postData) => {
    try {
        let result = await api.put(Apis.UpdateApplicantData, postData);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

//Job position API
export const createJobPosition = async (data) => {
    try{
        let jobPosition = await api.post(Apis.CreateJobPosition, data);
        if(jobPosition.data){
            return jobPosition.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}

export const fetchJobPositionById = async (id) =>{
    try{
        let jobPosition = await api.get(Apis.GetJobPositionById(id));
        if(jobPosition.data){
            return jobPosition.data
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const updateJobPosition = async (data) => {
    try{
        let jobPosition = await api.put(Apis.UpdateJobPosition, data);
        if(jobPosition.data){
            return jobPosition.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const deleteJobPosition = async (id) =>{
    try{    
        let jobPosition = await api.delete(Apis.DeleteJobPosition(id));
        if(jobPosition.data){
            return jobPosition.data
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}


export const fetchAllJobPositionData = async () => {
    try {
        let result = await api.get(Apis.GetJobPositionList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const jobPositionStatusChange = async (data) =>{
    try{
        let jobPosition = await api.put(Apis.UpdateJobPositionStatus, data);
        if(jobPosition.data){
            return jobPosition.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}

export const fetchAllEnableJobPositionData = async () => {
    try {
        let result = await api.get(Apis.GetEnableJobPositionList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

//Job Description API
export const createJobDescription = async (data) => {
    try{
        let jobDescription = await api.post(Apis.CreateJobDescription, data);
        if(jobDescription.data){
            return jobDescription.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}

export const fetchJobDescriptionById = async (id) =>{
    try{
        let jobDescription = await api.get(Apis.GetJobDescriptionById(id));
        if(jobDescription.data){
            return jobDescription.data
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const updateJobDescription = async (data) => {
    try{
        let jobDescription = await api.put(Apis.UpdateJobDescription, data);
        if(jobDescription.data){
            return jobDescription.data;
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}

export const deleteJobDescription = async (id) =>{
    try{    
        let jobDescription = await api.delete(Apis.DeleteJobDescription(id));
        if(jobDescription.data){
            return jobDescription.data
        }
    }catch(error){
        console.log("Error", error);
        return null;
    }
}


export const fetchAllJobDescriptionData = async (jobType) => {
    try {
        let jobDescription = await api.get(Apis.GetJobDescriptionList(jobType));
        if (jobDescription.data) {
            return jobDescription.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const jobDescriptionStatusChange = async (data) =>{
    try{
        let jobDescription = await api.put(Apis.UpdateJobDescriptionStatus, data);
        if(jobDescription.data){
            return jobDescription.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}

//Meta Job Description API
export const createMetaJobDescription = async (data) => {
    try{
        let metaJobDescription = await api.post(Apis.CreateMetaJobDescription, data);
        if(metaJobDescription.data){
            return metaJobDescription.data
        }
    } catch(error){
        console.log("Error", error);
        return null;
    }
}

export const fetchAllMetaJobDescription = async (data) => {
    try {
        let jobDescription = await api.get(Apis.GetMetaJobDescription(data));
        if (jobDescription.data) {
            return jobDescription.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const metaJobDescriptionStatusChange = async (data) =>{
    try{
        let jobDescription = await api.put(Apis.UpdateMetaJobDescriptionStatus, data);
        if(jobDescription.data){
            return jobDescription.data
        }
    }catch(error){
        console.log("Error", error);
        return null
    }
}