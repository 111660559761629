import { CSVLink, CSVDownload } from "react-csv";
import { CButton } from "@coreui/react";

const ExportCSV = ({ headers,data, filename, ...props  }) => {
    const epochTogmt = (date) => {
        var newDate = new Date(date).toLocaleDateString("en-US")
        return newDate;
    };
    let date = new Date();
    let dateMs = date.valueOf();
    const file = filename+"-"+epochTogmt(dateMs)+"-"+dateMs+".csv";
    return (
        <CSVLink headers={headers} data={data} filename={file}>
            <CButton><i class="fa-solid fa-download" /> <span className="d-none">Export CSV</span></CButton>
        </CSVLink>
    )
};

export default ExportCSV;
