import React, { useEffect, useRef, useState } from "react";
import GetBusinessDetails from "../../../components/services/GetBusinessDetails";
import { businessStatusOption, categoryOptions, deleteModalSwal, epochTogmt, lookingForOptions } from "../../../utils/Common";
import alertPopUp from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Select from 'react-select';
import DataTable from "react-data-table-component";
import { customStyles } from "../../../utils/tableCustomStyles";
import { tableBusinessConstant } from "../tableBusinessConstant";
import ExportCSV from "../../../utils/ExportCsvButton";
import authService from "src/auth/auth.service";
import { CSVLink } from "react-csv";

const MySwal = withReactContent(alertPopUp);

export default function businessList() {
  const csvLink = useRef()
  const [rowData, setRowData] = useState([]);
  const [selectData, setSelectData] = useState({ category: { value: 'ALL', label: 'ALL' }, lookingFor: { value: 'ALL', label: 'ALL' }, statusData: { value: 'NEW', label: 'NEW' }, });
  const [exportCsvData, setExportCsvData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [fileName, setFileName] = useState("");

  const businessListHeader = [
    { label: "Create Date", key: "createTime" },
    { label: "category", key: "category" },
    { label: "Looking For", key: "lookingFor" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Region", key: "region" },
    { label: "Country code", key: "countryCode" },
    { label: "Phone No", key: "phoneNumber" },
    { label: "Email", key: "email" },
    { label: "Company Name", key: "companyName" },
    { label: "Website", key: "website" },
    { label: "Tenure", key: "tenure" },
    { label: "Currency Type", key: "currencyType" },
    { label: "Estimated Budget", key: "amount" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Country", key: "country" },
    { label: "Status", key: "status" },
    { label: "Closed by", key: "closedBy" }
  ]

  const handleDelete = async (id) => {
    const response = await GetBusinessDetails.deleteBusinessData(id);
    if (response?.code === 200) {
      fetchBusinessList(1, perPage);
    }
  }

  const fetchBusinessList = async (page, size) => {
    let filterData = {
      category: selectData?.category?.value,
      lookingFor: selectData?.lookingFor?.value,
      status: selectData?.statusData?.value,
      page: page || 1,
      size: size
    };
    const response = await GetBusinessDetails.getAllBusinessList(filterData);
    if (response?.code === 200) {
        setRowData(response?.results?.content);
        setLoading(false);
        setTotalRows(response?.results?.totalElements);
    }
  }

  const getBusinessList = async () => {
    let filterData = {
      category: selectData?.category?.value,
      lookingFor: selectData?.lookingFor?.value,
      status: selectData?.statusData?.value,
      page: 1,
      size: 100
    };
    const response = await GetBusinessDetails.getAllBusinessList(filterData);
    let csvData = []
    if (response?.code === 200) {
      let date = new Date();
      let dateMs = date.valueOf();
      const file = `business_list_` + epochTogmt(dateMs) + "-" + dateMs + ".csv";
      response?.results && response?.results && response?.results?.content.map((item) => {
        let count = 0;
        let output = [];
        item.lookingFor && item?.lookingFor.map((data, index) => {
          if (index < 1) {
            output.push(data);
          } else {
            count = count + 1;
          }
        });
        csvData.push({ "category": item.category, "lookingFor": item?.lookingFor, "firstName": item?.firstName, "lastName": item?.lastName, "phoneNumber": item?.contactNumber, "email": item.email, "companyName": item?.companyName, "website": item?.website, "city": item?.location?.city, "state": item?.location?.state, "country": item?.location?.country, "createTime": item?.createTime, "id": item.id, "currentSpend": item?.currentSpend ? item?.currentSpend : "NA", "amount": item?.estimatedBudget?.amount, "currencyType": item?.location?.country === "India" ? "INR" : "USD", "tenure": item?.estimatedBudget?.type, "countryCode": item?.countryISDCode ? "+" + item?.countryISDCode : "", region: item.country, status: item?.status, closedBy: item?.closedBy || "-" })
      })
      setExportCsvData(csvData);
      setFileName(file);
      setTimeout(() => {
          csvLink.current.link.click()
      }, 1000)
    }
  };

  useEffect(() => {
    fetchBusinessList(1, 10);
  }, [selectData]);

  const handlePageChange = page => {
    fetchBusinessList(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
      setPerPage(newPerPage);
      fetchBusinessList(page, newPerPage);
  };


  const handleSelectChange = (selectedOption, selectType) => {
    setSelectData({ ...selectData, [selectType]: selectedOption });
  };

  const handleConfirmModal = (id) => {
    const handleConfirm = async () => {
      const swalval = await MySwal.fire(deleteModalSwal);
      if (swalval && swalval.value === true) {
        handleDelete(id)
      }
    };
    handleConfirm();
  };

  const actionButton = () => {
      return (
          <React.Fragment>
              {authService.getPermission("BUSINESS_EXPORT_CSV") && rowData.length > 0 && <a className="btn btn-primary" href={"javascript:void(0)"} onClick={() => getBusinessList()} ><i class="fa-solid fa-download" /></a>}
          </React.Fragment>
      );
  };


  return (
    <div className="row" style={{ margin: '10px 0' }}>
      <div className="col-md-12 mb-3">
        <div className="filterSection">
          <div className="col-md-2" style={{marginRight: "10px"}}>
            <Select
              options={categoryOptions}
              placeholder={"Filter by status"}
              value={selectData.category}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'category')}
              classNamePrefix="select"
                styles={{
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                        const color = '#03a68059';
                        return {
                            ...styles,
                            backgroundColor: isDisabled
                                ? null
                                : isSelected
                                    ? '#03a68059'
                                    : isFocused
                                        ? '#03a68059'
                                        : null,
                            color: isDisabled
                                ? '#ccc'
                                : isSelected
                                    ? color > 2
                                        ? '#00374d'
                                        : '#00374d'
                                    : data.color,
                            cursor: isDisabled ? 'not-allowed' : 'default',

                            ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? '#03a68059' : '#03a68059'),
                            },
                        };
                    },
                }}
            />
          </div>
          <div className="col-md-3" style={{marginRight: "10px"}}>
            <Select
              options={lookingForOptions}
              placeholder={"Filter by looking for"}
              value={selectData.lookingFor}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'lookingFor')}
              classNamePrefix="select"
                styles={{
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                        const color = '#03a68059';
                        return {
                            ...styles,
                            backgroundColor: isDisabled
                                ? null
                                : isSelected
                                    ? '#03a68059'
                                    : isFocused
                                        ? '#03a68059'
                                        : null,
                            color: isDisabled
                                ? '#ccc'
                                : isSelected
                                    ? color > 2
                                        ? '#00374d'
                                        : '#00374d'
                                    : data.color,
                            cursor: isDisabled ? 'not-allowed' : 'default',

                            ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? '#03a68059' : '#03a68059'),
                            },
                        };
                    },
                }}
            />
          </div>
          <div className="col-md-2">
            <Select
              options={businessStatusOption}
              placeholder={"Filter by status"}
              value={selectData.statusData}
              onChange={(selectedOption) => handleSelectChange(selectedOption, 'statusData')}
              classNamePrefix="select"
                styles={{
                    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                        const color = '#03a68059';
                        return {
                            ...styles,
                            backgroundColor: isDisabled
                                ? null
                                : isSelected
                                    ? '#03a68059'
                                    : isFocused
                                        ? '#03a68059'
                                        : null,
                            color: isDisabled
                                ? '#ccc'
                                : isSelected
                                    ? color > 2
                                        ? '#00374d'
                                        : '#00374d'
                                    : data.color,
                            cursor: isDisabled ? 'not-allowed' : 'default',

                            ':active': {
                                ...styles[':active'],
                                backgroundColor: !isDisabled && (isSelected ? '#03a68059' : '#03a68059'),
                            },
                        };
                    },
                }}
            />
          </div>
        </div>
      </div>
      <div className="col2Large">
        <DataTable
          title="Business Query"
          columns={tableBusinessConstant(fetchBusinessList, handleConfirmModal)}
          data={rowData}
          customStyles={customStyles}
          actions={actionButton()}
          pagination
          paginationPerPage={perPage}
          pointerOnHover
          fixedHeader
          noContextMenu
          fixedHeaderScrollHeight={"calc(100vh - 226px)"}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </div>

      <CSVLink headers={businessListHeader} data={exportCsvData} filename={fileName} ref={csvLink}></CSVLink>
    </div>
    )
}
