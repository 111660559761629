import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

export const createBlog = async (data) => {
  try {
    let result = await api.post(Apis.CreateBlog, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateBlog = async (id, data) => {
  try {
    let result = await api.post(`${Apis.EditBlog(id)}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchAllBlogData = async (data) => {
  try {
    let result = await api.get(Apis.GetBlogList(data));
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getBlogData = async (pageId) => {
  try {
    let result = await api.get(`${Apis.GetBlogData(pageId)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateBlogStatus = async (data) => {
  try {
    let result = await api.put(`${Apis.UpdateBlogStatus}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteBlog = async (id) => {
  try {
    let result = await api.delete(`${Apis.DeleteBlog(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getRelatedBlogByCategory = async (id) => {
  try {
    let result = await api.get(`${Apis.GetRelatedBlogByCategory(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAllAssetByPageId = async (id) =>{
  try{
    let result = await api.get(`${Apis.GetEditorImageAttachments(id)}`);
    if(result.data){
      return result.data;
    }
  }catch(error){
    console.log(error);
    return null;
  }
}
