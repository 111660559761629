import api from "../../../config/ApiConfig";
import { Apis } from "src/config";


export const createSignUpCode = async (data) => {
    try {
        let result = await api.post(Apis.CreateSignUpCode, data);
        if (result?.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const getSignUpCodeList = async () => {
    try {
        let result = await api.get(Apis.SignUpCodeList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updateSignUpCodeStatus = async (data) => {
    try {
        let result = await api.put(Apis.UpdateSignUpCodeStaus, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const deleteSignUpCode = async (id) => {
    try {
        let result = await api.delete(Apis.DeleteSignUpCode(id));
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};