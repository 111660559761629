import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

export const createPosition = async (data) => {
    try {
        let result = await api.post(Apis.CreatePostion, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updatePosition = async (data, id) => {
    try {
        let result = await api.put(`${Apis.UpdatePostion(id)}`, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const fetchAllPosition = async () => {
    try {
        let result = await api.get(Apis.GetPostiontList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getPositionData = async (id) => {
    try {
        let result = await api.get(`${Apis.GetPostionById(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updatePositionStatus = async (data) => {
    try {
        let result = await api.put(`${Apis.UpdatePostionStatus}`, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const deletePosition = async (id) => {
    try {
        let result = await api.delete(`${Apis.DeletePostion(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getEnablePosition = async () => {
    try {
        let result = await api.get(`${Apis.GetEnablePostion}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}