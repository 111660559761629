import React, { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";
import grapesjsPluginExport from "grapesjs-plugin-export";
import grapesjsPresetWebpage from "grapesjs-preset-webpage";
import "grapesjs/dist/css/grapes.min.css";
import "./editor.css";
import { storageSetting } from "./utils";
import grapejsRte from "grapesjs-rte-extensions";
import axios from "axios";
import { uploadAttachmentFile } from "src/components/services/UploadAttachmentApi";
import { getAllAssetByPageId } from "src/components/services/BlogApi";
import $ from "jquery";

let token = JSON.parse(localStorage.getItem('user'))?.token

function Editor(props) {
  const [editor, setEditor] = useState(null);
  const editorRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [hasset, setAssets] = useState([]);

  const uploadAttachments = async (currentFile) => {
    const formData = new FormData();
    formData.append("image", currentFile);
    formData.append("type", "image");
    formData.append("pageId", props.pageId);
    let response = await uploadAttachmentFile(formData);
    if (response?.code === 200) {
      console.log("response", response);
      getAttachmentsByPageId(props.pageId);
    }
  };
  const newAssets = [
    {
      src: "https://raize-the-bar.s3.ap-south-1.amazonaws.com/rtb/a46b13db7b41c9212988ebac4a05ebe7",
      name: "Asset 1",
      type: "image",
      category: "Images",
    },
    {
      src: "https://raize-the-bar.s3.ap-south-1.amazonaws.com/rtb/a46b13db7b41c9212988ebac4a05ebe7",
      name: "Asset 2",
      type: "image",
      category: "Images",
    },
  ];

  const getAttachmentsByPageId = async (id) => {
    let newAssets = await getAllAssetByPageId(id);
    console.log("new Asset", newAssets);
    const convertedAssets = newAssets.results.map((asset, index) => ({
      src: asset.src,
      name: `Asset ${index + 1}`,
      type: asset.type,
      category: asset.category,
    }));
    console.log("convertedAssets", convertedAssets);
    setAssets(convertedAssets);
  };

  useEffect(() => {
    const editor = grapesjs.init({
      container: "#editor",
      storageManager: storageSetting(props.pageId),
      plugins: [
        gjsBlockBasic,
        grapesjsPluginExport,
        grapesjsBlockBootstrap,
        grapejsRte,
        (editor) =>
          grapesjsPresetWebpage(editor, {
            modalImportTitle: "Import Template",
            modalImportLabel:
              '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
            modalImportContent: function (editor) {
              return (
                editor.getHtml() + "<style>" + editor.getCss() + "</style>"
              );
            },
          }),
      ],

      pluginsOpts: {
        gjsBlockBasic: {
          blocks: [
            "column1",
            "column2",
            "column3",
            "column3-7",
            "link",
            "image",
            "video",
            "map",
          ],
        },
      },

      styleManager: {
        sectors: [
          {
            name: "General",
            buildProps: [
              "float",
              "display",
              "position",
              "top",
              "right",
              "left",
              "bottom",
            ],
            properties: [
              {
                name: "Alignment",
                property: "float",
                type: "radio",
                defaults: "none",
                list: [
                  { value: "none", className: "fa fa-times" },
                  { value: "left", className: "fa fa-align-left" },
                  { value: "right", className: "fa fa-align-right" },
                ],
              },
              { property: "position", type: "select" },
            ],
          },
          {
            name: "Dimension",
            open: false,
            buildProps: [
              "width",
              "max-width",
              "min-width",
              "height",
              "max-height",
              "min-height",
              "margin",
              "padding",
            ],
            properties: [
              {
                id: "flex-width",
                type: "integer",
                name: "Width",
                units: ["px", "%"],
                property: "flex-basis",
                toRequire: 1,
              },
              {
                property: "margin",
                properties: [
                  { name: "Top", property: "margin-top" },
                  { name: "Right", property: "margin-right" },
                  { name: "Bottom", property: "margin-bottom" },
                  { name: "Left", property: "margin-left" },
                ],
              },
              {
                property: "padding",
                properties: [
                  { name: "Top", property: "padding-top" },
                  { name: "Right", property: "padding-right" },
                  { name: "Bottom", property: "padding-bottom" },
                  { name: "Left", property: "padding-left" },
                ],
                defaults: "0",
              },
            ],
          },
          {
            name: "Typography",
            open: false,
            buildProps: [
              "font-family",
              "font-size",
              "font-weight",
              "letter-spacing",
              "color",
              "line-height",
              "text-align",
              "text-decoration",
              "text-shadow",
            ],
            properties: [
              { name: "Font", property: "font-family" },
              { name: "Weight", property: "font-weight" },
              { name: "Font color", property: "color" },
              {
                property: "text-align",
                type: "radio",
                defaults: "left",
                list: [
                  {
                    value: "left",
                    name: "Left",
                    className: "fa fa-align-left",
                  },
                  {
                    value: "center",
                    name: "Center",
                    className: "fa fa-align-center",
                  },
                  {
                    value: "right",
                    name: "Right",
                    className: "fa fa-align-right",
                  },
                  {
                    value: "justify",
                    name: "Justify",
                    className: "fa fa-align-justify",
                  },
                ],
              },
              {
                property: "text-decoration",
                type: "radio",
                defaults: "none",
                list: [
                  { value: "none", name: "None", className: "fa fa-times" },
                  {
                    value: "underline",
                    name: "underline",
                    className: "fa fa-underline",
                  },
                  {
                    value: "line-through",
                    name: "Line-through",
                    className: "fa fa-strikethrough",
                  },
                ],
              },
              {
                property: "text-shadow",
                properties: [
                  { name: "X position", property: "text-shadow-h" },
                  { name: "Y position", property: "text-shadow-v" },
                  { name: "Blur", property: "text-shadow-blur" },
                  { name: "Color", property: "text-shadow-color" },
                ],
              },
            ],
          },
          {
            name: "Decorations",
            open: false,
            buildProps: [
              "opacity",
              "border-radius",
              "border",
              "box-shadow",
              "background-bg",
            ],
            properties: [
              {
                type: "slider",
                property: "opacity",
                defaults: 1,
                step: 0.01,
                max: 1,
                min: 0,
              },
              {
                property: "border-radius",
                properties: [
                  { name: "Top", property: "border-top-left-radius" },
                  { name: "Right", property: "border-top-right-radius" },
                  { name: "Bottom", property: "border-bottom-left-radius" },
                  { name: "Left", property: "border-bottom-right-radius" },
                ],
              },
              {
                property: "box-shadow",
                properties: [
                  { name: "X position", property: "box-shadow-h" },
                  { name: "Y position", property: "box-shadow-v" },
                  { name: "Blur", property: "box-shadow-blur" },
                  { name: "Spread", property: "box-shadow-spread" },
                  { name: "Color", property: "box-shadow-color" },
                  { name: "Shadow type", property: "box-shadow-type" },
                ],
              },
              {
                id: "background-bg",
                property: "background",
                type: "bg",
              },
            ],
          },
          {
            name: "Extra",
            open: false,
            buildProps: ["transition", "perspective", "transform"],
            properties: [
              {
                property: "transition",
                properties: [
                  { name: "Property", property: "transition-property" },
                  { name: "Duration", property: "transition-duration" },
                  { name: "Easing", property: "transition-timing-function" },
                ],
              },
              {
                property: "transform",
                properties: [
                  { name: "Rotate X", property: "transform-rotate-x" },
                  { name: "Rotate Y", property: "transform-rotate-y" },
                  { name: "Rotate Z", property: "transform-rotate-z" },
                  { name: "Scale X", property: "transform-scale-x" },
                  { name: "Scale Y", property: "transform-scale-y" },
                  { name: "Scale Z", property: "transform-scale-z" },
                ],
              },
            ],
          },
          {
            name: "Flex",
            open: false,
            properties: [
              {
                name: "Flex Container",
                property: "display",
                type: "select",
                defaults: "block",
                list: [
                  { value: "block", name: "Disable" },
                  { value: "flex", name: "Enable" },
                ],
              },
              {
                name: "Flex Parent",
                property: "label-parent-flex",
                type: "integer",
              },
              {
                name: "Direction",
                property: "flex-direction",
                type: "radio",
                defaults: "row",
                list: [
                  {
                    value: "row",
                    name: "Row",
                    className: "icons-flex icon-dir-row",
                    title: "Row",
                  },
                  {
                    value: "row-reverse",
                    name: "Row reverse",
                    className: "icons-flex icon-dir-row-rev",
                    title: "Row reverse",
                  },
                  {
                    value: "column",
                    name: "Column",
                    title: "Column",
                    className: "icons-flex icon-dir-col",
                  },
                  {
                    value: "column-reverse",
                    name: "Column reverse",
                    title: "Column reverse",
                    className: "icons-flex icon-dir-col-rev",
                  },
                ],
              },
              {
                name: "Justify",
                property: "justify-content",
                type: "radio",
                defaults: "flex-start",
                list: [
                  {
                    value: "flex-start",
                    className: "icons-flex icon-just-start",
                    title: "Start",
                  },
                  {
                    value: "flex-end",
                    title: "End",
                    className: "icons-flex icon-just-end",
                  },
                  {
                    value: "space-between",
                    title: "Space between",
                    className: "icons-flex icon-just-sp-bet",
                  },
                  {
                    value: "space-around",
                    title: "Space around",
                    className: "icons-flex icon-just-sp-ar",
                  },
                  {
                    value: "center",
                    title: "Center",
                    className: "icons-flex icon-just-sp-cent",
                  },
                ],
              },
              {
                name: "Align",
                property: "align-items",
                type: "radio",
                defaults: "center",
                list: [
                  {
                    value: "flex-start",
                    title: "Start",
                    className: "icons-flex icon-al-start",
                  },
                  {
                    value: "flex-end",
                    title: "End",
                    className: "icons-flex icon-al-end",
                  },
                  {
                    value: "stretch",
                    title: "Stretch",
                    className: "icons-flex icon-al-str",
                  },
                  {
                    value: "center",
                    title: "Center",
                    className: "icons-flex icon-al-center",
                  },
                ],
              },
              {
                name: "Flex Children",
                property: "label-parent-flex",
                type: "integer",
              },
              {
                name: "Order",
                property: "order",
                type: "integer",
                defaults: 0,
                min: 0,
              },
              {
                name: "Flex",
                property: "flex",
                type: "composite",
                properties: [
                  {
                    name: "Grow",
                    property: "flex-grow",
                    type: "integer",
                    defaults: 0,
                    min: 0,
                  },
                  {
                    name: "Shrink",
                    property: "flex-shrink",
                    type: "integer",
                    defaults: 0,
                    min: 0,
                  },
                  {
                    name: "Basis",
                    property: "flex-basis",
                    type: "integer",
                    units: ["px", "%", ""],
                    unit: "",
                    defaults: "auto",
                  },
                ],
              },
              {
                name: "Align",
                property: "align-self",
                type: "radio",
                defaults: "auto",
                list: [
                  {
                    value: "auto",
                    name: "Auto",
                  },
                  {
                    value: "flex-start",
                    title: "Start",
                    className: "icons-flex icon-al-start",
                  },
                  {
                    value: "flex-end",
                    title: "End",
                    className: "icons-flex icon-al-end",
                  },
                  {
                    value: "stretch",
                    title: "Stretch",
                    className: "icons-flex icon-al-str",
                  },
                  {
                    value: "center",
                    title: "Center",
                    className: "icons-flex icon-al-center",
                  },
                ],
              },
            ],
          },
        ],
      },
      assetManager: {
        storageType: "",
        storeOnChange: true,
        storeAfterUpload: true,
        embedAsBase64: true, // Make this false to upload image to AmazonS3
        assets: [],
        // headers: {}, // Custom headers to pass with the upload request
        // params: {}, // Custom parameters to pass with the upload request, eg. csrf token
        credentials: "include", // The credentials setting for the upload request, eg. 'include', 'omit'
        autoAdd: 1, // If true, tries to add automatically uploaded assets.
        dropzone: 0, // Enable an upload dropzone on the entire editor (not document) when dragging files over it
        openAssetsOnDrop: 1, // Open the asset manager once files are been dropped via the dropzone
        multiUpload: true, // Allow uploading multiple files per request. If disabled filename will not have '[]' appended
        showUrlInput: true, // Toggles visiblity of assets url input
        uploadFile: function (e) {
          // Ref: https://blog.webnersolutions.com/adding-image-upload-feature-in-grapesjs-editor/
          var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          var formData = new FormData();
          formData.append("image", files[0]);
          formData.append("type", "image");
          formData.append("pageId", props.pageId);
          $.ajax({
            url: `${process.env.REACT_APP_BACKEND_API}/api/file/upload`, // Save image as base64 encoded - Its a function
            // url: siteURL + 'uploadImage', // Upload image to AmazonS3 - Its a function
            headers: {
                'token': token
            },
            type: "POST",
            data: formData,
            contentType: false,
            crossDomain: true,
            dataType: "json",
            mimeType: "multipart/form-data",
            processData: false,
            success: function (result) {
              console.log("result", result);
              // while using base64 encode => 0: {name: "ipsumImage.png", type: "image", src: "data:image/png;base64,iVBORw0KGgAAVwA…AAAAAAAAAAAAAAAD4Pv4B6rBPej6tvioAAAAASUVORK5CYII=", height: 145, width: 348}
              // while using AmazonS3 => 0: {name: "logo_sigclub.png", type: "image", src: "https://amazonaws.com/assets/CONTENT/img/logo_sigclub.png", status: true, message: "Uploaded successfully", …}
              editor.AssetManager.add(result.results);
            },
          });
        },
      },
      canvas: {
        styles: [
          "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css",
          "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css",
        ],
        scripts: [
          "https://code.jquery.com/jquery-3.3.1.slim.min.js",
          "https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js",
          "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js",
        ],
      },
    });

    editor.getConfig().showDevices = 0;

    editor.Panels.addPanel({
      id: "devices",
      buttons: [
        {
          id: "set-device-desktop",
          command: function (e) {
            return e.setDevice("Desktop");
          },
          className: "fa fa-desktop",
          active: 1,
        },
        {
          id: "set-device-tablet",
          command: function (e) {
            return e.setDevice("Tablet");
          },
          className: "fa fa-tablet",
        },
        {
          id: "set-device-mobile",
          command: function (e) {
            return e.setDevice("Mobile portrait");
          },
          className: "fa fa-mobile",
        },
      ],
    });

    editor.RichTextEditor.add("superscript", {
      icon: "<b>S<sup>s</sup></b>",
      attributes: { title: "Superscript" },
      result: (rte) => rte.exec("superscript"),
    });

    editor.RichTextEditor.add("hyperlink", {
      icon: "&#128279;",
      attributes: { title: "Hyperlink" },
      result: (rte) => {
        var component = editor.getSelected();

        if (component.is("link")) {
          component.replaceWith(`${component.get("content")}`);
        } else {
          var range = rte.selection().getRangeAt(0);

          var container = range.commonAncestorContainer;
          if (container.nodeType == 3) container = container.parentNode;

          if (container.nodeName === "A") {
            var sel = rte.selection();
            sel.removeAllRanges();
            range = document.createRange();
            range.selectNodeContents(container);
            sel.addRange(range);
            rte.exec("unlink");
          } else {
            var url = window.prompt("Enter the URL to link to:");
            if (url)
              rte.insertHTML(
                `<a class="link" href="${url}">${rte.selection()}</a>`
              );
          }
        }
      },
    });

    editor.RichTextEditor.add("indent", {
      icon: "&#8594;",
      attributes: { title: "Indent" },
      result: (rte) => rte.exec("indent"),
    });

    editor.RichTextEditor.add("outdent", {
      icon: "&#8592;",
      attributes: { title: "Outdent" },
      result: (rte) => rte.exec("outdent"),
    });

    editor.RichTextEditor.add("orderedList", {
      icon: "1.",
      attributes: { title: "Ordered List" },
      result: (rte) => rte.exec("insertOrderedList"),
    });

    editor.RichTextEditor.add("unorderedList", {
      icon: "&#8226;",
      attributes: { title: "Unordered List" },
      result: (rte) => rte.exec("insertUnorderedList"),
    });

    editor.on("load", function () {
      var blockManager = editor.BlockManager;
      blockManager.remove("text");
    });

    // Create a custom blockquote component
    const blockquoteComponent = editor.BlockManager.add("blockquote", {
      label: "Blockquote",
      category: "Text",
      attributes: { class: "fa fa-quote-right" },
      content:
        '<blockquote class="block-quote" style="font-size: 1.4em; margin: 50px 15px 30px; border: none !important; font-family: Open Sans; color: #000000; padding: 24px 30px 24px 130px; line-height: 1.6; position: relative; background: #F3FFFD; border-radius: 20px;"><i class="fa-solid fa-quote-left" style="background:#03A680; width:80px; height:66px; border-radius: 5px; display: flex; align-items: center; justify-content: center; color: #ffffff; font-size: 40px; position: absolute; left: 30px; top: -30px;"></i> This is a blockquote</blockquote>',
    });

    const TextComponent = editor.BlockManager.add("dsd", {
      label: "Text",
      category: "Basic",
      attributes: {
        class: "gjs-fonts gjs-f-text gjs-block gjs-one-bg gjs-four-color-h",
      },
      content:
        '<p style="font-family: Poppins, sans-serif;">Please enter text!</p>',
    });

    // Add the custom blockquote component to the toolbar
    editor.Panels.getButton("views", "open-blocks").once("click", () => {
      const category = editor.BlockManager.get("text").get("category");
      editor.BlockManager.get(category).add(blockquoteComponent);
    });

    editor.Panels.getButton("views", "open-blocks").once("click", () => {
      const category = editor.BlockManager.get("text").get("category");
      editor.BlockManager.get(category).add(TextComponent);
    });

    setEditor(editor);
  }, [hasset]);

  return (
    <>
      <div id="editor"></div>
    </>
  );
}

export default Editor;
