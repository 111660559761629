import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CForm, CCol } from "@coreui/react";
import { useEffect, useState } from "react";
import { updateBlogStatus } from "../components/services/BlogApi";
import { changeApplicantStatusData, departmentStatusChange, jobDescriptionStatusChange, jobPositionStatusChange, metaJobDescriptionStatusChange, positionStatusChange } from "../components/services/CareerApi";
import { updateCategoryStatus } from "../components/services/CategoryApi";
import { updateInfrograpicStatus } from "../components/services/InfrograpicApi";
import { updateInterestStatus } from "../components/services/InterestApi";
import { updatePersonaStatus } from "../components/services/PersonaApi";
import { updatePositionStatus } from "../components/services/PositionApi";
import { updateTeamStatus } from "../components/services/TeamDetails";
import { updateTriviaStatus } from "../components/services/TriviaApi";
import { errorMessagePopUp } from "./Common";
import { updateRoleStatus } from "../components/services/RoleApi";
import GetBusinessDetails from "../components/services/GetBusinessDetails"
import authService from "src/auth/auth.service";
import TextArea from "./TextArea";
import { updateSignUpCodeStatus } from "src/components/services/SignUpCodeApi";
import { updateSubscriptionPlanStatus } from "src/components/services/SubscriptionPlanApi";
import { changeUserViewQueryStatus } from "src/components/services/UserViewQuery";

const StatusDrop = (props) => {

    const [status, setStatus] = useState();
    const [content, setContent] = useState();
    const [userData] = useState(authService.getUserDetailsFromLocalStorage());
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState("");
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        setReason(event.target.value.trimStart())
    }

    const statusChange = async (data) => {
        let postData = {
            [props.type !== "SIGN_UP_CODE" ? "status" : "codeStatus"]: props.type === "BUSINESS_QUERY" || props.type === "APPLICANT" || props.type === "PAGE_QUERY" ? data : status
        };
        if (props.type === "BLOG") {
            postData.blogId = props?.id;
        } else if (props.type === "TEAM") {
            postData.teamId = props?.id;
        } else if (props.type === "POSITION") {
            postData.postionId = props?.id;
        } else if (props.type === "SUBSCRIPTION_PLAN" || props.type === "SIGN_UP_CODE" || props.type === "DEPARTMENT" || props.type === "ROLE" || props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY" || props.type === "APPLICANT" || props.type === "CATEGORY" || props.type === "BUSINESS_REMARKS" || props.type === "CAREERPOSITION" || props.type === "JOB_DESCRIPTION" || props.type === "JOB_DESCRIPTION_POSITION") {
            postData.id = props?.id;
            if ((props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY") && postData.status === "CLOSED") {
                postData.closedBy = userData.name;
            }
            if(props.type === "BUSINESS_REMARKS") {
                postData.lastUpdatedBy = userData.name;
            }
        }

        let response = {};
        if (props.type === "BLOG") {
            response = await updateBlogStatus(postData);
        } else if (props.type === "TEAM") {
            response = await updateTeamStatus(postData);
        } else if (props.type === "PERSONA") {
            response = await updatePersonaStatus(postData, props?.id);
        } else if (props.type === "CATEGORY") {
            response = await updateCategoryStatus(postData);
        } else if (props.type === "POSITION") {
            response = await updatePositionStatus(postData);
        } else if (props.type === "INTEREST") {
            response = await updateInterestStatus(postData, props?.id);
        } else if (props.type === "TRIVIA") {
            response = await updateTriviaStatus(postData, props?.id);
        } else if (props.type === "INFROGRAPIC") {
            response = await updateInfrograpicStatus(postData, props?.id);
        } else if (props.type === "DEPARTMENT") {
            response = await departmentStatusChange(postData)
        } else if (props.type === "CAREERPOSITION") {
            response = await jobPositionStatusChange(postData)
        }  else if (props.type === "JOB_DESCRIPTION") {
            response = await jobDescriptionStatusChange(postData)
        } else if(props.type === "JOB_DESCRIPTION_POSITION") {
            response = await metaJobDescriptionStatusChange(postData)
        } else if (props.type === "ROLE") {
            response = await updateRoleStatus(postData)
        } else if (props.type === "BUSINESS_QUERY") {
            response = await GetBusinessDetails.changeBusinessStatusData(postData);
        } else if (props.type === "PAGE_QUERY") {
            response = await changeUserViewQueryStatus(postData);
        } else if (props.type === "APPLICANT") {
            if(postData.status === "OFFER_DENIED") {
                setVisible(true);
            } else {
                response = await changeApplicantStatusData(postData);
            }
        } else if(props.type === "BUSINESS_REMARKS") {
            response = await GetBusinessDetails.changeBusinessRemarksStatus(postData);
        } else if(props.type === "SIGN_UP_CODE") {
            response = await updateSignUpCodeStatus(postData);
        }else if(props.type === "SUBSCRIPTION_PLAN") {
            response = await updateSubscriptionPlanStatus(postData);
        }

        if (response?.code === 202 || response?.status === "202 ACCEPTED") {
            props.action();
        }
        if (response?.code === 409) {
            errorMessagePopUp(response?.message);
        }
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (!reason) {
            errors['reason'] = "Please enter reason";
            formIsValid = false;
        }
        setErrors(errors);
        setTimeout(() => {
            setErrors({});
        }, 3000);
        return formIsValid;
    }

    const handleSubmitReason = async (event) => {
        event.preventDefault();
        if(validateForm()) {
            let postData = {
                status: "OFFER_DENIED",
                reason: reason,
                id: props?.id
            }
            let response = await changeApplicantStatusData(postData);
            if (response?.code === 202) {
                props.action();
            }
            if (response?.code === 409) {
                errorMessagePopUp(response?.message);
            }
        }
    }

    useEffect(() => {
        let content = "";
        let status = "";
        if (props.status === "DRAFT") {
            content = "Enable";
            status = "ENABLE";
        } else if (props.status === "ENABLE") {
            content = "Disable";
            status = "DISABLE";
        } else if (props.status === "DISABLE") {
            content = "Enable";
            status = "ENABLE";
        } else if (props.status === "NEW") {
            content = props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY" ? "Invalid" : "Offered";
            status = props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY" ? "INVALID" : "OFFERED";
        } else if (props.status === "INVALID") {
            content = "Rejected";
            status = "REJECTED";
        } else if (props.status === "REJECTED") {
            content = props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY" ? "Qualified" : "Offer denied";
            status = props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY" ? "QUALIFIED" : "OFFER_DENIED";
        } else if (props.status === "QUALIFIED") {
            content ="Closed";
            status = "CLOSED";
        } else if ((props.status === "CLOSED" || props.status === "INVOLVED") && props.type !== "BUSINESS_REMARKS") {
            content = props.type === "JOB_DESCRIPTION_POSITION" ? "Open" : "Rejected";
            status = props.type === "JOB_DESCRIPTION_POSITION" ? "OPEN" : "REJECTED";
        } else if (props.status === "REPEATED" || props.status === "INVOLVED") {
            content =  "Rejected";
            status = "REJECTED";
        } else if (props.status === "KEPT_ON_HOLD" || props.status === "OFFER_DENIED") {
            content = "Joined";
            status = "JOINED";
        } else if (props.status === "OFFERED") {
            content = "Kept on hold";
            status = "KEPT_ON_HOLD";
        } else if (props.status === "JOINED") {
            content = "Involved";
            status = "INVOLVED";
        }  else if (props.status === "OPEN") {
            content = props.type === "JOB_DESCRIPTION_POSITION" ? "Publish" : "Closed";
            status = props.type === "JOB_DESCRIPTION_POSITION" ? "PUBLISH" : "CLOSED";
        }  else if (props.status === "CLOSED" && props.type === "BUSINESS_REMARKS") {
            content = "Open";
            status = "OPEN";
        } else if (props.status === "PUBLISH") {
            content = "Closed";
            status = "CLOSED";
        } else if (props.status === "ENABLED" || props.status === "CONSUMED") {
            // content = "Consumed";
            // status = "CONSUMED";
            content = "Disable";
            status = "DISABLED";
        } 
        // else if (props.status === "CONSUMED") {
        //     content = "Disable";
        //     status = "DISABLED";
        // }
         else if (props.status === "DISABLED") {
            content = "Enable";
            status = "ENABLED";
        }
        setStatus(status);
        setContent(content);
    })

    return (
        <>
            <CDropdown>
                <CDropdownToggle color="light" disabled={!authService.getPermission(props?.publishPermission)}>{content}</CDropdownToggle>
                <CDropdownMenu>
                    {(props.type === "BUSINESS_QUERY" || props.type === "PAGE_QUERY") ?
                        <>
                            <CDropdownItem disabled={true} onClick={statusChange}>New</CDropdownItem>
                            <CDropdownItem disabled={props.status !== "INVALID" ? false : true} onClick={() => statusChange("INVALID")}>Invalid</CDropdownItem>
                            <CDropdownItem disabled={props.status !== "REJECTED" ? false : true} onClick={() => statusChange("REJECTED")}>Rejected</CDropdownItem>
                            <CDropdownItem disabled={props.status !== "QUALIFIED" ? false : true} onClick={() => statusChange("QUALIFIED")}>Qualified</CDropdownItem>
                            <CDropdownItem disabled={props.status !== "CLOSED" ? false : true} onClick={() => statusChange("CLOSED")}>Closed</CDropdownItem>
                            <CDropdownItem disabled={props.status !== "REPEATED" ? false : true} onClick={() => statusChange("REPEATED")}>Repeated</CDropdownItem>
                        </> :
                        props.type === "APPLICANT" ?
                            <>
                                <CDropdownItem disabled={true} onClick={statusChange}>New</CDropdownItem>
                                <CDropdownItem disabled={props.status !== "OFFERED" ? false : true} onClick={() => statusChange("OFFERED")}>Offered</CDropdownItem>
                                <CDropdownItem disabled={props.status !== "KEPT_ON_HOLD" ? false : true} onClick={() => statusChange("KEPT_ON_HOLD")}>Kept on hold</CDropdownItem>
                                <CDropdownItem disabled={props.status !== "JOINED" ? false : true} onClick={() => statusChange("JOINED")}>Joined</CDropdownItem>
                                <CDropdownItem disabled={props.status !== "INVOLVED" ? false : true} onClick={() => statusChange("INVOLVED")}>Involved</CDropdownItem>
                                <CDropdownItem disabled={props.status !== "REJECTED" ? false : true} onClick={() => statusChange("REJECTED")}>Rejected</CDropdownItem>
                                <CDropdownItem disabled={props.status !== "OFFER_DENIED" ? false : true} onClick={() => statusChange("OFFER_DENIED")}>Offer denied</CDropdownItem>
                            </> :
                        props.type === "BUSINESS_REMARKS" || props.type === "JOB_DESCRIPTION_POSITION" ?
                            <>
                                <CDropdownItem disabled={content === "Open" ? false : true} onClick={statusChange}>Open</CDropdownItem>
                                {props.type === "JOB_DESCRIPTION_POSITION" && <CDropdownItem disabled={content === "Publish" ? false : true} onClick={statusChange}>Publish</CDropdownItem>}
                                <CDropdownItem disabled={content === "Closed" ? false : true} onClick={statusChange}>Closed</CDropdownItem>
                            </> :
                        props.type === "SIGN_UP_CODE" ?
                            <>
                                <CDropdownItem disabled={content === "Enable" ? false : true} onClick={statusChange}>Enable</CDropdownItem>
                                <CDropdownItem disabled={content === "Disable" ? false : true} onClick={statusChange}>Disable</CDropdownItem>
                            </> :
                            <>
                                <CDropdownItem disabled={content === "Draft" ? false : true} onClick={statusChange}>Draft</CDropdownItem>
                                <CDropdownItem disabled={content === "Enable" ? false : true} onClick={statusChange}>Enable</CDropdownItem>
                                <CDropdownItem disabled={content === "Disable" ? false : true} onClick={statusChange}>Disable</CDropdownItem>
                            </>
                    }
                </CDropdownMenu>
            </CDropdown>
            <CModal visible={visible} onClose={() => setVisible(false)} aria-labelledby="LiveDemoExampleLabel">
                <CModalBody>
                    <CForm className="row g-3">
                        <CCol md={12}>
                            <TextArea
                                value={reason}
                                label={"Reason"}
                                name={"reason"}
                                onChange={handleChange}
                                error={errors["reason"]}
                                required={true}
                            />
                        </CCol>
                    </CForm>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => {setVisible(false); setReason("")}}>
                        Close
                    </CButton>
                    <CButton color="primary" onClick={handleSubmitReason}>Submit</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
};

export default StatusDrop;