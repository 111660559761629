import api from "../../../config/ApiConfig";
import { Apis } from "src/config";


export const addTeamMember = async (data) => {
    try{
        let result  = await api.post(Apis.CreateTeam, data, { 'Access-Control-Allow-Origin': '*' })
        if(result.data){
            return result.data;
        }
    }catch(error){
        console.log(error);
        return null;
    }
};

export const getAllTeamList = async () => {
    try {
      let result = await api.get(Apis.GetTeamList);
      if (result.data) {
        return result.data;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const handleDeleteTeamMember = async (id) => {

  try {
    let result = await api.delete(`${Apis.DeleteTeam(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};


export const handleUpdateTeamMember = async (id,data) => {

  try {
    let result = await api.put(`${Apis.UpdateTeam(id)}`,data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTeamDataById = async (id) => {

  try {
    let result = await api.get(`${Apis.TeamById(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateTeamStatus = async (data) => {
  try {
    let result = await api.put(`${Apis.UpdateTeamStatus}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

