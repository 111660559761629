import axios from 'axios';
import authService from 'src/auth/auth.service';

let token = JSON.parse(localStorage.getItem('user'))?.token

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: {
        "Content-Type": "application/json",
        "token": token
    }
})

api.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if(error.response?.status === 401) {
            authService.logout();
            return <Navigate to="/login" />
        }
        if(error.response?.status === 409) {
            return error?.response;
        }
        //return Promise.reject(error);
    }
);

export default api;