import { Apis } from "src/config";
import api from "src/config/ApiConfig";

const getAllBusinessList = async (filterData) => {
  try {
    let result = await api.get(`${Apis.GetBusinessList(filterData)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

const getBusinessDetails = async (id) => {
  try {
    let result = await api.get(Apis.GetBusinessDetails + id);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log("Error", error);
    return null;
  }
}

const deleteBusinessData = async (id) => {
  try {
    let result = await api.delete(Apis.DeleteBusinessQuery(id));
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

const changeBusinessStatusData = async (postData) => {
  try {
    let result = await api.put(Apis.ChangeStatusBusinessQuery, postData);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

const getAllRemarksList = async (data) => {
  try {
    let result = await api.get(`${Apis.GetBusinessRemarksList(data)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

const createBusinessRemarks = async (data) => {
  try {
    let result = await api.post(`${Apis.CreateBusinessRemarks}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

const getBusinessRemarksById = async (data) => {
  try {
    let result = await api.get(`${Apis.GetBusinessRemarksDataById(data)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

const updateBusinessRemarks = async (data) => {
  try {
    let result = await api.post(`${Apis.UpdateBusinessRemarks}`, data);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

const deleteBusinessRemarks = async (id) => {
  try {
    let result = await api.delete(`${Apis.DeleteBusinessRemarks(id)}`);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

const changeBusinessRemarksStatus = async (postData) => {
  try {
    let result = await api.put(`${Apis.ChangeBusinessRemarksStatus}`, postData);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.log(error);
    return error?.response?.data;
  }
};

export default { getAllBusinessList, deleteBusinessData, getBusinessDetails, changeBusinessStatusData, getAllRemarksList, createBusinessRemarks, getBusinessRemarksById, updateBusinessRemarks, deleteBusinessRemarks, changeBusinessRemarksStatus };
