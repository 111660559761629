// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Sedgwick+Ave+Display&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container-lg {
  max-width: 100% !important;
}

.gjs-pn-devices-c {
  display: none !important;
}

.gjs-one-bg {
  background: #fff !important;
}

.gjs-two-color {
  color: #000 !important;
}

.gjs-four-color {
  color: #000;
}

.gjs-four-color-h:hover {
  color: #a6a6a6 !important;
}


`, "",{"version":3,"sources":["webpack://./src/views/pages/editor/editor.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Sedgwick+Ave+Display&display=swap');\nbody {\n  margin: 0;\n  padding: 0;\n}\n\nhtml {\n  overflow: scroll;\n  overflow-x: hidden;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.container-lg {\n  max-width: 100% !important;\n}\n\n.gjs-pn-devices-c {\n  display: none !important;\n}\n\n.gjs-one-bg {\n  background: #fff !important;\n}\n\n.gjs-two-color {\n  color: #000 !important;\n}\n\n.gjs-four-color {\n  color: #000;\n}\n\n.gjs-four-color-h:hover {\n  color: #a6a6a6 !important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
