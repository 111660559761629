import api from "../../../config/ApiConfig";
import { Apis } from "src/config";

export const createPersona = async (data) => {
    try {
        let result = await api.post(Apis.CreatePerosna, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updatePersona = async (data, id) => {
    try {
        let result = await api.put(`${Apis.UpdatePersona(id)}`, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const fetchAllPersonaData = async () => {
    try {
        let result = await api.get(Apis.GetPerosnaList);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getPersonaData = async (id) => {
    try {
        let result = await api.get(`${Apis.GetPerosnaById(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const updatePersonaStatus = async (data, id) => {
    try {
        let result = await api.put(`${Apis.UpdatePersonaStatus(id)}`, data);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};


export const deletePersona = async (id) => {
    try {
        let result = await api.delete(`${Apis.DeletePersona(id)}`);
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
};
